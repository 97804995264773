import { createBrowserHistory, History, LocationState } from 'history';

interface RouteNavigation {
  currentPath(): string;
  navigateTo(path: string): void;
  navigateToWithProps(path: string, state: LocationState | undefined): void;
  popAndNavigateTo(path: string): void;
  forward(): void;
  backward(): void;
}

export const reactRouterHistory: History<LocationState> =
  createBrowserHistory();

export const RouterNavigation: RouteNavigation = {
  currentPath: (): string => reactRouterHistory.location.pathname,
  navigateTo: (path: string): void => reactRouterHistory.push(path),
  navigateToWithProps: (path: string, state: LocationState | undefined): void =>
    reactRouterHistory.push(path, state),
  popAndNavigateTo: (path: string): void => reactRouterHistory.replace(path),
  forward: (): void => reactRouterHistory.goForward(),
  backward: (): void => reactRouterHistory.goBack(),
};

export const ROUTES = {
  VERSIONING_TABLE: '/VersioningTable',
  REVISIONS: '/Revisions',
  LOGIN_PAGE: '/',
  LOGIN_MFA: '/LoginMFA',
  MAIN: '/main',
  SETUP_MFA: '/SetupMFA',
  SETUP_AUTHAPP: '/AuthApp/:secretcode',
  VERIFY_TEXTCODE_MFA: '/VerifyTextCodeMFA',
  VERIFY_AUTHAPP_MFA: '/VerifyAuthAppMFA',
  OTP_TEXTCODE: '/OTPTextCode',
  OTP_AUTHAPP: '/OTPAuthApp',
  NEW_PASSWORD_REQUIRED: '/SetPermanentPassword',
  VERIFY_PHONE: '/VerifyPhone',
  ADVICE_BOARD: '/AdviceBoard',
};
