import { UserAttributes } from '@app/reducers/user/types';
import {
  COOKIES,
  deleteAllCookies,
  deleteCookie,
  resetAllCookies,
  getCookie,
  setCookie,
} from '@utility/cookies';
import { RouterNavigation, ROUTES } from '@utility/route-navigation';
import {
  API_ENDPOINT_URL,
  WP_ENDPOINT_URL,
  DOMAIN,
  SERVICE_DOMAIN,
} from '@utility/urls';
import {
  LoginParams,
  SecretCode,
  SessionResponse,
} from './interface/session-data';
import {
  AuthDetailsParams,
  OTPStatusParams,
  User,
  UserData,
} from './interface/user-data';
import SessionController from './session-controller';
import UserController from './user-controller';
import WPController from './wp-controller';

class AppController {
  private session: SessionController;
  private wp: WPController;
  private userController: UserController;
  private userData: User | null;
  private domain: string;

  constructor() {
    const config = {
      baseURL: API_ENDPOINT_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const wpConfig = {
      ...config,
      baseURL: WP_ENDPOINT_URL,
    };

    this.session = new SessionController(config);
    this.wp = new WPController(wpConfig);
    this.userController = new UserController(config);

    this.userData = null;
    this.domain = DOMAIN;
    if (this.domain !== 'localhost') {
      this.domain = `.${SERVICE_DOMAIN}`;
    }
  }

  saveUserData = (userData: UserData): User => {
    const now: Date = new Date();
    const expiry: Date = new Date(
      now.getTime() + userData.AuthenticationResult.ExpiresIn * 1000
    );

    return {
      email: userData.email || '',
      username: userData.username || '',
      userRole: userData.userRole || '',
      first_name: userData.first_name || '',
      last_name: userData.last_name || '',
      tokenInfo: {
        accessToken: userData.AuthenticationResult.AccessToken,
        idToken: userData.AuthenticationResult.IdToken,
        refreshToken: userData.AuthenticationResult.RefreshToken,
        expiry,
      },
    };
  };

  updateSessionTokens(userData: UserData) {
    if (this.userData) {
      const now: Date = new Date();
      const expiry: Date = new Date(
        now.getTime() + userData.AuthenticationResult.ExpiresIn * 1000
      );
      this.userData.tokenInfo.accessToken =
        userData.AuthenticationResult.AccessToken;
      this.userData.tokenInfo.idToken = userData.AuthenticationResult.IdToken;
      this.userData.tokenInfo.expiry = expiry;

      setCookie(
        COOKIES.ID_TOKEN,
        this.userData.tokenInfo.idToken,
        this.domain,
        this.userData.tokenInfo.expiry
      );
      setCookie(
        COOKIES.ACCESS_TOKEN,
        this.userData.tokenInfo.accessToken,
        this.domain,
        this.userData.tokenInfo.expiry
      );
      this.updateAuthorizationHeader();
    }
  }

  saveUserInfo() {
    if (this.userData === null) {
      return;
    }

    setCookie(
      COOKIES.ID_TOKEN,
      this.userData.tokenInfo.idToken,
      this.domain,
      this.userData.tokenInfo.expiry
    );
    setCookie(
      COOKIES.ACCESS_TOKEN,
      this.userData.tokenInfo.accessToken,
      this.domain,
      this.userData.tokenInfo.expiry
    );
    setCookie(
      COOKIES.REFRESH_TOKEN,
      this.userData.tokenInfo.refreshToken,
      this.domain
    );
    setCookie(COOKIES.EMAIL, this.userData.email, this.domain);
    setCookie(COOKIES.FIRST_NAME, this.userData.first_name, this.domain);
    setCookie(COOKIES.LAST_NAME, this.userData.last_name, this.domain);
    setCookie(COOKIES.ROLE, this.userData.userRole, this.domain);
    setCookie(COOKIES.USERNAME, this.userData.username, this.domain);
  }

  async refreshToken() {
    const idToken = getCookie(COOKIES.ID_TOKEN);
    if (!idToken) {
      if (this.userData) {
        const response = await this.session.refreshToken({
          username: this.userData.username,
          refresh_token: this.userData.tokenInfo.refreshToken,
        });
        if (response.status === 200) {
          this.updateSessionTokens((response.data as SessionResponse).Message);
        } else {
          RouterNavigation.navigateTo(ROUTES.LOGIN_PAGE);
        }
      }
    }
  }

  updateAuthorizationHeader() {
    const idToken = getCookie(COOKIES.ID_TOKEN);
    if (idToken) {
      this.session.updateAuthorizationHeader(idToken);
      this.userController.updateAuthorizationHeader(idToken);
    }
  }

  async login(parameters: LoginParams) {
    const response = await this.session.login(parameters);
    if (response.status === 200) {
      this.setUserDetails(response.data as UserData);
    }
    return response;
  }

  async getAuthDetails(parameters: AuthDetailsParams) {
    return this.wp.authDetails(parameters);
  }

  async setOTPStatus(parameters: OTPStatusParams) {
    await this.wp.otpStatus(parameters);
  }

  setUserDetails(data: UserData) {
    // if login is successful, clear the
    // localStorage before processing the response.
    localStorage.clear();
    this.userData = this.saveUserData(data);
    this.saveUserInfo();
    this.updateAuthorizationHeader();
  }

  cleanup(): void {
    resetAllCookies(this.domain);
    deleteAllCookies(this.domain);
    localStorage.clear();
    this.userData = null;
  }

  logout(): void {
    this.cleanup();
    RouterNavigation.navigateTo(ROUTES.LOGIN_PAGE);
  }

  async addSoftwareMfa() {
    const response = await this.session.addSoftwareMfa({
      username: getCookie(COOKIES.OTP_SESSION),
      access_token: getCookie(COOKIES.ACCESS_TOKEN),
    });

    if (response.status === 200) {
      /* eslint-disable-next-line  @typescript-eslint/no-unsafe-return */
      return (response.data as SecretCode).SecretCode;
    }
    return '';
  }

  async verifySoftwareMfa(otp_code: string) {
    return this.session.verifySoftwareMfa({
      username: getCookie(COOKIES.OTP_SESSION),
      access_token: getCookie(COOKIES.ACCESS_TOKEN),
      otp_code,
    });
  }

  async removeSoftwareMfa() {
    return this.session.removeSoftwareMfa({
      username: getCookie(COOKIES.OTP_SESSION) || '',
      access_token: getCookie(COOKIES.ACCESS_TOKEN),
    });
  }

  async verifySoftwareMfaToken(otp_code: string) {
    const response = await this.session.verifySoftwareMfaToken({
      username: getCookie(COOKIES.EMAIL),
      otp_code,
      session: getCookie(COOKIES.OTP_SESSION),
    });

    if (response.status === 200) {
      // if OTP Verify is successful, clear the
      // localStorage before processing the response.
      localStorage.clear();
      this.userData = this.saveUserData(response.data as UserData);
      this.saveUserInfo();
      this.updateAuthorizationHeader();
      deleteCookie(COOKIES.OTP_SESSION, this.domain);
    }
    return response;
  }

  async addSMSMfa() {
    return this.session.addSMSMfa({
      username: getCookie(COOKIES.OTP_SESSION),
      access_token: getCookie(COOKIES.ACCESS_TOKEN),
    });
  }

  async removeSMSMfa() {
    return this.session.removeSMSMfa({
      username: getCookie(COOKIES.OTP_SESSION) || '',
      access_token: getCookie(COOKIES.ACCESS_TOKEN),
    });
  }

  async verifySMSMfaToken(otp_code: string) {
    const response = await this.wp.verifySMSMfaToken({
      username: getCookie(COOKIES.EMAIL),
      otp_code,
      session: getCookie(COOKIES.OTP_SESSION),
    });

    if (response.status === 200) {
      // if OTP Verify is successful, clear the
      // localStorage before processing the response.
      localStorage.clear();
      this.userData = this.saveUserData(response.data as UserData);
      this.saveUserInfo();
      this.updateAuthorizationHeader();
      deleteCookie(COOKIES.OTP_SESSION, this.domain);
    }
    return response;
  }

  // Users
  async getUser(username: string) {
    await this.refreshToken();
    const response = await this.userController.getUser(username);
    return { user: response.data };
  }

  async updateUserAttributes(attributes: UserAttributes) {
    await this.refreshToken();
    return this.userController.updateUserAttributes(
      getCookie(COOKIES.USERNAME),
      attributes,
      getCookie(COOKIES.ACCESS_TOKEN)
    );
  }

  async setPermanentPassword(tempPassword: string, newPassword: string) {
    try {
      await this.refreshToken();
      const response = await this.userController.setPermanentPassword(
        getCookie(COOKIES.EMAIL),
        tempPassword,
        newPassword
      );
      if (response.data.AuthenticationResult) {
        this.userData = this.saveUserData(response.data as UserData);
        this.saveUserInfo();
        this.updateAuthorizationHeader();
      }
      return response;
    } catch (e) {
      return {
        data: {
          error: true,
        },
      };
    }
  }
}

export default new AppController();
